
import { eFilterType } from '@/store/modules/filter/types'
import { Component, Mixins } from 'vue-property-decorator'
import { FilterMixin } from '../mixins/filterMixin'

@Component({})
export default class LeftMenu extends Mixins(FilterMixin) {
  get categories() {
    return this.leftNavigations.filter(f => f?.type == eFilterType.Category)
  }

  get subcategories() {
    return this.leftNavigations.filter(f => f?.type == eFilterType.Subcategory && f.parentId == this.selectedCategory?.id)
  }
}
